import {defineStore} from 'pinia'

export const useAudioStore = defineStore('audio', {
  state: () => {
    return {
      // 所有这些属性都将自动推断其类型
      audioDom:{} as HTMLAudioElement,
      audio_play:false,
    }
  },
  actions:{
    setaudioDom(dom:any){
      this.audioDom = dom;
    },
    setAudioPlay(is:boolean){
      this.audio_play = is;
    }
  },
})