import "core-js/modules/es.array.push.js";
import { defineComponent as _defineComponent } from 'vue';
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';
export default /*@__PURE__*/_defineComponent({
  __name: 'navBar',
  props: {
    title: {
      type: String,
      default: ""
    },
    isBack: {
      type: Boolean,
      default: true
    },
    url: {
      type: String,
      default: ""
    },
    isWhite: {
      type: Boolean,
      default: true
    },
    rightTitle: {
      type: String,
      default: ""
    },
    righturl: {
      type: String,
      default: ""
    }
  },
  setup(__props) {
    let router = useRouter();
    let prop = __props;
    function clickLeft() {
      if (prop.isBack) {
        if (prop.url) {
          router.push(prop.url);
        } else {
          router.back();
        }
      } else {
        router.push(prop.url);
      }
    }
    function clikcRight() {
      console.log(prop.righturl);
      router.push(prop.righturl);
    }
    function showWhiteBackground() {
      if (prop.isWhite) {
        document.documentElement.style.setProperty('--van-nav-bar-background', 'var(--van-background-2)');
        document.documentElement.style.setProperty('--van-nav-bar-icon-color', '#333');
        document.documentElement.style.setProperty('--van-nav-bar-title-text-color', '#333');
      } else {
        document.documentElement.style.setProperty('--van-nav-bar-background', 'var(--LoginGradient)');
        document.documentElement.style.setProperty('--van-nav-bar-icon-color', 'var(--textW)');
        document.documentElement.style.setProperty('--van-nav-bar-title-text-color', 'var(--textW)');
      }
    }
    onMounted(() => {
      showWhiteBackground();
      window.addEventListener("resize", () => {
        showWhiteBackground();
      });
    });
    return (_ctx, _cache) => {
      const _component_van_nav_bar = _resolveComponent("van-nav-bar");
      return _openBlock(), _createBlock(_component_van_nav_bar, {
        "left-arrow": __props.isBack,
        border: false,
        onClickLeft: _cache[0] || (_cache[0] = $event => clickLeft()),
        title: __props.title,
        "right-text": __props.rightTitle,
        onClickRight: _cache[1] || (_cache[1] = $event => clikcRight())
      }, _createSlots({
        _: 2
      }, [_ctx.$slots.left ? {
        name: "left",
        fn: _withCtx(() => [_renderSlot(_ctx.$slots, "left")]),
        key: "0"
      } : undefined, _ctx.$slots.title ? {
        name: "title",
        fn: _withCtx(() => [_renderSlot(_ctx.$slots, "title")]),
        key: "1"
      } : undefined, _ctx.$slots.right ? {
        name: "right",
        fn: _withCtx(() => [_renderSlot(_ctx.$slots, "right")]),
        key: "2"
      } : undefined]), 1032, ["left-arrow", "title", "right-text"]);
    };
  }
});