import { createApp } from 'vue'
import Loading from './loading.vue'


const mountNode = document.createElement('div')
let Instance;



const loading = () => {
  Instance = createApp(Loading, {})
  document.body.appendChild(mountNode)
  Instance.mount(mountNode)
}
loading.show = ()=>{
  loading()
}
loading.close = ()=>{
  document.body.removeChild(mountNode);
  Instance.unmount(); 
  
}

export default loading