import axios from 'axios'

import { useRouter } from 'vue-router';
import { showToast, showLoadingToast, closeToast } from 'vant';
import loading from "../components/loading";



//超时时间
let time = 50000;
// 请求域名
// let url = '';
let router = useRouter();
axios.defaults.timeout = time;
console.log(process.env.VUE_APP_ENV == 'development')
// 开发环境
if (process.env.VUE_APP_ENV == 'production') {
    axios.defaults.baseURL = process.env.VUE_APP_API;
} else {
    axios.defaults.baseURL = process.env.VUE_APP_API;
}
// 生产环境
// axios.defaults.baseURL = "/";
//请求拦截

let request_count = 0;

axios.interceptors.request.use(
    async config => {
        // 每次发送请求之前判断vuex中是否存在token        
        // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断 
        // config.headers.authorization = sessionStorage.getItem('token')

        let list = ['/main/user/getgametime', '/main/user/getopenlogs', '/main/user/getlotteryopenstatistics','/main/user/getopenlogssz','/main/user/getgametimesz','/main/user/getgametimed5','/main/user/getopenlogsd5','/main/user/getgametimebjl','/main/user/getgametimedzp','/main/user/getlastwinmoney','/main/user/getopenlogsfj','/main/user/getlotterylogsfj','/main/user/getgametimefj','/main/user/getwink35d','/main/user/GetLongDragon']
        // console.log(list.indexOf(config.url) == -1);
        if (list.indexOf(config.url) == -1) {
            // console.log(config.url);
            if (request_count === 0) {
                // $loading.show();
                loading.show()
            }
            request_count++;
        }

        if (localStorage.getItem("token") == '') {
            // showToast()
            router.push('/login');
        }

        return config;
    },
    error => {
        // closeToast()
        return Promise.reject(error);
    })
// 响应拦截器
axios.interceptors.response.use(
    response => {
        // console.log(request_count)
        if(request_count){
            if (request_count <= 1) {
                loading.close();
                
                request_count = 0;
            }else{
                --request_count;
            }
        }
        
        closeToast()
        if (response.status === 200) {
            if (response.data.message == 'please log in first' || response.data.message == 'please login in first') {
                // router.push('/login')
                // return Promise.reject(response.data.message);
                // return false;
                window.location.href = process.env.VUE_APP_ENV == 'development' ? '/#/login' : "/wap/#/login";
            }
            return Promise.resolve(response); //进行中 

        } else {
            return response; //失败       
        }
    },
    // 服务器状态码不是200的情况    
    error => {
        if (axios.isCancel(error)) {

            console.log('request cancel ', JSON.stringify(error))
            return new Promise(() => { })
        }
        console.log(error);
        if (error.response.status) {
            switch (error.response.status) {
                // 401: 未登录                
                // 未登录则跳转登录页面，并携带当前页面的路径                
                // 在登录成功后返回当前页面，这一步需要在登录页操作。                
                case 401:
                    break
                // 403 token过期                
                // 登录过期对用户进行提示                
                // 清除本地token和清空vuex中token对象                
                // 跳转登录页面                
                case 403:
                    sessionStorage.clear()
                    router.push('/login')
                    break
                // 404请求不存在                
                case 404:
                    break;
                // 其他错误，直接抛出错误提示                
                default:
            }
            closeToast()
            return Promise.reject(error.response);
        }
    }
);
/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
const $get = (url: string, params: any) => {
    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params,
        })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
const $post = (url: string, params: any) => {
    return new Promise((resolve, reject) => {
        axios.post(url, params) //是将对象 序列化成URL的形式，以&进行拼接   
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}
export {    
    $get,
    $post
}
//下面是vue3必须加的，vue2不需要，只需要暴露出去get，post方法就可以
export default {
    install: (app: any) => {
        app.config.globalProperties['$get'] = $get;
        app.config.globalProperties['$post'] = $post;
        app.config.globalProperties['$axios'] = axios;
    }
}
