import {defineStore} from 'pinia'

export const useMainStore = defineStore('main', {
  state: () => {
    return {
      // 所有这些属性都将自动推断其类型
      viewWidth : "" ,
      is_one:false,
    }
  },
  actions:{
    setViewWidth(width:string){
      this.viewWidth = width;
    },
    setIsOne(is:boolean){
      this.is_one = is;
    }
  },
})