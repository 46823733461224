import {defineStore} from 'pinia'

export const useMenuStore = defineStore('menu', {
  state: () => {
    return {
      active : localStorage.getItem("menuActive") ? parseInt(localStorage.getItem("menuActive") || "") : 0
    }
  },
  actions:{
    setActive(index:number){
      this.active = index;
      localStorage.setItem("menuActive",String(index));
    }
  }
})