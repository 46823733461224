import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, isRef as _isRef, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/img/common/logo.png';
const _hoisted_1 = {
  style: {
    "position": "absolute",
    "top": "50%",
    "left": "50%",
    "transform": "translate(-50%,-50%)",
    "width": "280px",
    "height": "280px"
  }
};
import { ref } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'loading',
  setup(__props, {
    expose: __expose
  }) {
    let showCenter = ref(true);
    function show() {
      console.log("123");
      showCenter.value = true;
    }
    function close() {
      showCenter.value = false;
    }
    __expose({
      show,
      close
    });
    return (_ctx, _cache) => {
      const _component_van_loading = _resolveComponent("van-loading");
      const _component_van_popup = _resolveComponent("van-popup");
      return _openBlock(), _createBlock(_component_van_popup, {
        show: _unref(showCenter),
        "onUpdate:show": _cache[0] || (_cache[0] = $event => _isRef(showCenter) ? showCenter.value = $event : showCenter = $event),
        style: {
          "background": "transparent",
          "height": "100%",
          "width": "100%"
        }
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_loading, {
          color: "#0094ff",
          size: "280"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [_createElementVNode("img", {
            style: {
              "height": "40px",
              "width": "180px",
              "position": "relative",
              "top": "-160px",
              "left": "40px"
            },
            src: _imports_0,
            alt: "",
            srcset: ""
          }, null, -1)])),
          _: 1
        })])]),
        _: 1
      }, 8, ["show"]);
    };
  }
});