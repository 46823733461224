export const testrouters =[
  {
    path:"/myprofile",
    component: () => import('@/views/my/myprofile/myprofile.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/loginlog",
    component: () => import('@/views/my/loginlog/loginlog.vue'),
    meta:{
      keepAlive:false,
    }    
  },
  {
    path:"/bettinghistory",
    component: () => import('@/views/my/bettinghistory/bettinghistory.vue'),    
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/transactionrecord",
    component: () => import('@/views/my/transactionrecord/transactionrecord.vue'),    
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/updatepassword",
    component: () => import('@/views/my/updatepassword/updatepassword.vue'),   
    meta:{
      keepAlive:false,
    } 
  },
  {
    path:"/gift",
    component: () => import('@/views/my/gift/gift.vue'),    
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/about",
    component: () => import('@/views/my/about/about.vue'), 
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/infodetails",
    component: () => import('@/views/info/infodetails/infodetails.vue'),   
    meta:{
      keepAlive:false,
    } 
  },
  {
    path:"/bank",
    component: () => import('@/views/money/bank/bank.vue'),    
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/withdrawals",
    component: () => import('@/views/money/withdrawals/withdrawals.vue'),   
    meta:{
      keepAlive:false,
    } 
  },
  {
    path:"/recharge",
    component: () => import('@/views/money/recharge/recharge.vue'),
    meta:{
      keepAlive:false,
    }    
  },
  {
    path:"/rechargerecord",
    component: () => import('@/views/money/rechargerecord/rechargerecord.vue'),    
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/withdrawalsrecord",
    component: () => import('@/views/money/withdrawalsrecord/withdrawalsrecord.vue'),    
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/win",
    component: () => import('@/views/home/game/win/win.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/bettingrecordwin",
    component: () => import('@/views/home/game/win/bettingrecordwin.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/bettingrecordk3",
    component: () => import('@/views/home/game/k3game/bettingrecordk3.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/bettingrecord5d",
    component: () => import('@/views/home/game/d5game/bettingrecord5d.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/turntable",
    component: () => import('@/views/home/game/turntable/turntable.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/k3game",
    component: () => import('@/views/home/game/k3game/k3game.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/plane",
    component: () => import('@/views/home/game/plane/plane.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/d5game",
    component: () => import('@/views/home/game/d5game/d5game.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/baccarat",
    component: () => import('@/views/home/game/baccarat/baccarat.vue'),  
    meta:{
      keepAlive:false,
    }  
  },
  {
    path:"/login",
    component: () => import('@/views/login/login.vue'),
    meta:{
      keepAlive:false,
    }    
  },
  {
    path:"/findpassword",
    component: () => import('@/views/login/findpassword.vue'),    
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/teamdetails",
    component: () => import('@/views/team/teamdetails/teamdetails.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/signin",
    component: () => import('@/views/info/signin/signin.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/signinhistory",
    component: () => import('@/views/info/signin/history.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/depositbonus",
    component: () => import('@/views/info/depositbonus/depositbonus.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/lossrewards",
    component: () => import('@/views/info/lossrewards/lossrewards.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/rules",
    component: () => import('@/views/home/game/rules/index.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/record",
    component: () => import('@/views/money/record/record.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/signup",
    component: () => import('@/views/login/signup.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/options",
    component: () => import('@/views/my/customer/options.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/customer",
    component: () => import('@/views/my/customer/index.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/questions",
    component: () => import('@/views/my/customer/questions.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/other",
    component: () => import('@/views/my/customer/other.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/signinrules",
    component: () => import('@/views/info/signin/rules.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/rebateratio",
    component: () => import('@/views/team/rebateratio.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/teamrules",
    component: () => import('@/views/team/rules.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/teaminvite",
    component: () => import('@/views/team/invite.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/rebate",
    component: () => import('@/views/info/rebate/rebate.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/signuptips",
    component: () => import('@/views/login/signuptips.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/commission",
    component: () => import('@/views/team/commission.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/aboutoptions",
    component: () => import('@/views/my/about/aboutoptions.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/rabatehistory",
    component: () => import('@/views/info/rebate/rabatehistory.vue'),
    meta:{
      keepAlive:false,
    },
    
  },
  {
    path:"/activityaward",
    component: () => import('@/views/info/activityaward/index.vue'),
    meta:{
      keepAlive:false,
    },
  },
  {
    path:"/activityaward_record",
    component: () => import('@/views/info/activityaward/record.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/distribution",
    component: () => import('@/views/my/distribution/distribution.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/usdtrecharge",
    component: () => import('@/views/money/recharge/usdt_recharge.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/bulletins",
    component: () => import('@/views/home/bulletins.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/allview",
    component: () => import('@/views/home/allview.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/safe",
    component: () => import('@/views/safe/index.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/safehistory",
    component: () => import('@/views/safe/history.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/saferule",
    component: () => import('@/views/safe/rule.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/vip",
    component: () => import('@/views/my/vip/index.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/viphistory",
    component: () => import('@/views/my/vip/history.vue'),
    meta:{
      keepAlive:false,
    }
  },
  
  {
    path:"/gamestatistics",
    component: () => import('@/views/my/gamestatistics/index.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/feedback",
    component: () => import('@/views/my/feedback/index.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/settings",
    component: () => import('@/views/my/settings/index.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/acatar",
    component: () => import('@/views/my/settings/acatar.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/rechargeUsdtBank",
    component: () => import('@/views/money/bank/recharge_usdt.vue'),
    meta:{
      keepAlive:false,
    }
  },

  {
    path:"/beginnertutorial",
    component: () => import('@/views/my/beginnertutorial/beginnertutorial.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/AllOnlineGames",
    component: () => import('@/views/home/AllOnlineGames.vue'),
    meta:{
      keepAlive:false,
    }
  },
  {
    path:"/ChangLong",
    component: () => import('@/views/home/ChangLong.vue'),
    meta:{
      keepAlive:false,
    }
  },
];


