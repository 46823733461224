import { defineComponent as _defineComponent } from 'vue';
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  id: "isapp"
};
const _hoisted_2 = ["src"];
import { onMounted, ref } from 'vue';
import { useMainStore } from "./store/main";
import { useMenuStore } from './store/menu';
import { useAudioStore } from "./store/audio";
import { useRouter, useRoute } from 'vue-router';
import { computed } from 'vue';
export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {
    let mainstore = useMainStore();
    let userstore = useMenuStore();
    let router = useRouter();
    let route = useRoute();
    let audiostore = useAudioStore();
    let key = computed(() => {
      // console.log(route.path)
      // {{ router.currentRoute.value.path }}
      return route.path + Math.random();
    });
    let viewWidth = (doc, width) => {
      let html = document.querySelector('html');
      let body = document.querySelector('body');
      body.style = 'font-size:12px;';
      if (width >= 800) {
        // doc.style.width = '400px';
        // doc.style.margin = '0 auto';
        mainstore.setViewWidth("400px");
        html.style = 'font-size:40px;';
      } else if (width < 800 && width >= 540) {
        // doc.style.width = '540px';
        // doc.style.margin = '0 auto';
        mainstore.setViewWidth("540px");
        html.style = 'font-size:54px;';
      } else {
        mainstore.setViewWidth(width + 'px');
        html.style = `font-size:${width / 10}px;`;
      }
    };
    let audio = ref(null);
    function audioAutoPlay() {
      audio.value.load();
    }
    onMounted(() => {
      let isapp = document.querySelector("#app");
      viewWidth(isapp, window.innerWidth);
      window.addEventListener("resize", () => {
        viewWidth(isapp, window.innerWidth);
      });
      audiostore.setaudioDom(audio);
      document.addEventListener('touchstart', audioAutoPlay, true);
      audio.value.onended = function () {
        audiostore.setAudioPlay(false);
        // audio.value.load();
        // audio.value.play()
        document.removeEventListener('touchstart', audioAutoPlay);
      };
      // userstore.setActive(0);
      // router.push(routers[useMenuStore().active].path);
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_router_view, null, {
        default: _withCtx(({
          Component
        }) => [_createVNode(_Transition, null, {
          default: _withCtx(() => [(_openBlock(), _createBlock(_KeepAlive, null, [_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
            key: _ctx.$route.path
          })) : _createCommentVNode("", true)], 1024))]),
          _: 2
        }, 1024), !_ctx.$route.meta.keepAlive ? (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
          key: _ctx.$route.path
        })) : _createCommentVNode("", true)]),
        _: 1
      }), _withDirectives(_createElementVNode("audio", {
        ref_key: "audio",
        ref: audio,
        controls: ""
      }, [_createElementVNode("source", {
        src: require('@/assets/video/1.mp3'),
        type: "audio/mpeg"
      }, null, 8, _hoisted_2)], 512), [[_vShow, false]])]);
    };
  }
});