 export const routers = [{
  path:"/home",
  name:"home",
  component: () => import('@/views/home/index.vue'),
  meta:{
    icon:"home",
    activeIcon:"home_active",
    title:"Home",
    keepAlive:false,
  }
},
{
  path:"/info",
  name:"info",
  component: () => import('@/views/info/index.vue'),
  meta:{
    icon:"info",
    activeIcon:"info_active",
    title:"Activity",
    keepAlive:false,
  }
},
{
  path:"/team",
  name:"team",
  component: () => import('@/views/team/team.vue'),    
  meta:{
    icon:"share",
    activeIcon:"share_active",
    title:"Promotion",
    keepAlive:false,
  }
},
{
  path:"/wallet",
  name:"money",
  component: () => import('@/views/wallet/index.vue'),
  meta:{
    icon:"wallet",
    activeIcon:"wallet_active",
    title:"Wallet",
    keepAlive:false,
  }
},
{
  path:"/my",
  name:"my",
  component: () => import('@/views/my/index.vue'),
  meta:{
    icon:"me",
    activeIcon:"me_active",
    title:"Account",
    keepAlive:false,
  },
  
}]
 
 
 