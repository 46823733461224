import { createRouter, createWebHistory, RouteRecordRaw,createWebHashHistory } from 'vue-router'
import { routers } from '@/common/router/router'
import { testrouters } from '@/common/router/testrouter'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: 'home',
    children:routers,
  },
  ...testrouters
]


const router = createRouter({
  // history: createWebHistory(process.env.BASE_URL),
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async (to, from,next) => {
  window.scrollTo(0, 0);  
  if (to.path !== '/login' && to.path !== '/home' && to.path !== '/signup' && to.path !== '/findpassword' && to.path !== '/options' && to.path !== '/customer' && to.path !== '/questions' && to.path !== '/signuptips' ){
    console.log(localStorage.getItem('token') == null)
    if(localStorage.getItem('token') == null){
      next('/login') 
    }else{
      next();
    }
  } 
  else next() 
})

export default router
