import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'
import 'vant/es/toast/style'
import navBar from './components/navBar/navBar.vue'
import 'vant/es/toast/style'
import 'vant/es/dialog/style'

import request from './api/request'
// import axios from 'axios'
// import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';

import VueAwesomeSwiper from "vue-awesome-swiper";
import Lazyload from "vue3-lazyload";
// import "swiper/css/swiper.css";


let app = createApp(App)
app.component('navBar',navBar);
// app.use(TawkMessengerVue,{
//   propertyId : '65c3371c8d261e1b5f5d2d1f',
//   widgetId : '1hm18ui2u'
// });
// app.config.globalProperties.$axios=axios; 
app.use(Lazyload,{})
app.use(VueAwesomeSwiper)

app.use(createPinia()).use(router).use(request).mount('#app')

function rewriteLog() {
  console.log = (function (log) {
    return process.env.NODE_ENV == 'production' ? function () { } : log
  }(console.log))
}

rewriteLog();
